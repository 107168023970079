import React from 'react'

import loadable from '@loadable/component'

const PageBuilderContent = loadable(() => import('./PageBuilderContent'))
const PageBuilderHeading = loadable(() => import('./PageBuilderHeading'))
const PageBuilderRooms = loadable(() => import('./PageBuilderRooms'))
const PageBuilderRetreats = loadable(() => import('./PageBuilderRetreats'))
const PageBuilderRetreatsFilter = loadable(() => import('./PageBuilderFilter'))
const PageBuilderCoaching = loadable(() => import('./PageBuilderCoaching'))
const PageBuilderContentMedia = loadable(() =>
  import('./PageBuilderContentMedia')
)
const PageBuilderReviews = loadable(() => import('./PageBuilderReviews'))
const PageBuilderTestimonials = loadable(() =>
  import('./PageBuilderTestimonials')
)
const PageBuilderBanner = loadable(() => import('./PageBuilderBanner'))
const PageBuilderGallery = loadable(() => import('./PageBuilderGallery'))
const PageBuilderColumns = loadable(() => import('./PageBuilderColumns'))
const PageBuilderCardLinks = loadable(() => import('./PageBuilderCardLinks'))
const PageBuilderContactForm = loadable(() =>
  import('./PageBuilderContactForm')
)

function PageBuilder(props) {
  const { type, pageBuilder, location, pageId } = props

  // Load the right component, based on the _type from Sanity
  const Components = {
    pageBuilderContent: PageBuilderContent,
    pageBuilderHeading: PageBuilderHeading,
    pageBuilderRooms: PageBuilderRooms,
    pageBuilderRetreats: PageBuilderRetreats,
    pageBuilderFilter: PageBuilderRetreatsFilter,
    pageBuilderContentMedia: PageBuilderContentMedia,
    pageBuilderReviews: PageBuilderReviews,
    pageBuilderTestimonials: PageBuilderTestimonials,
    pageBuilderBanner: PageBuilderBanner,
    pageBuilderGallery: PageBuilderGallery,
    pageBuilderColumns: PageBuilderColumns,
    pageBuilderCardLinks: PageBuilderCardLinks,
    pageBuilderCoaching: PageBuilderCoaching,
    pageBuilderContactForm: PageBuilderContactForm,
  }

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return (
        <div key={index + block._key} className={block._type}>
          {React.createElement(Components[block._type], {
            key: block._key,
            block,
            index,
            type,
            raw: pageBuilder[index],
            location,
            pageId,
          })}
        </div>
      )
    }

    return null
  })
}

export default PageBuilder
